.products {
	height: 160vh;
	display: flex;
	flex-direction: column;
	align-items: center;

	.topline {
		position: relative;
		text-align: center;
		width: 100%;
		img {
			height: 50vh;
		}

		.title-container {
			position: absolute;
			top: 0;
			width: 50%;
			height: 100%;
			margin-left: 50%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			h4 {
				transform: rotate(90deg);
				font-size: 1.6vw;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				color: #000000;
				margin-left: -8%;
			}
		}
	}

	.content-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 110vh;

		.text-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 50vh;
		}
	}

	h6 {
		font-size: 1.6vw;
		letter-spacing: 0.08em;
		color: #000;
		margin-bottom: 0.25%;
		text-transform: uppercase;
	}

	h4 {
		font-size: 4.43vw;
		letter-spacing: 0.08em;
		color: #000;
		text-transform: uppercase;
	}

	p {
		font-size: 1.25vw;
		letter-spacing: 0.08em;
		color: #000;
		line-height: 1.35;
		width: 40%;
		text-align: center;
		margin-top: 2%;
	}

	// .a:hover {
	//     clip-path: polygon(50% 0%, 65% 15%, 30% 50%, 15% 35%);
	//     transition: 0.5s;
	// 	-webkit-transition: 0.5s;
	// 	-moz-transition: 0.5s;
	// 	-ms-transition: 0.5s;
	// 	-o-transition: 0.5s;
	// 	// -webkit-transform: rotate(-90deg);
	// 	// -moz-transform: rotate(-90deg);
	// 	// -o-transform: rotate(-90deg);
	// 	// -ms-transform: rotate(-90deg);
	// 	// transform: rotate(-90deg);
	// }

	.square2 {
		padding-top: 14rem;
		position: relative;
		// border: 1px solid blue;
		height: 60vh;
		width: 500px;

		.a,
		.b,
		.c,
		.d {
			position: absolute;
			transition: 0.5s;
			-webkit-transition: 0.5s;
			-moz-transition: 0.5s;
			-ms-transition: 0.5s;
			-o-transition: 0.5s;
			z-index: 1;
		}

		.rect1,
		.rect2,
		.rect3,
		.rect4 {
			position: absolute;
			z-index: 55555 !important;
			transition: 0.5s;
			-webkit-transition: 0.5s;
			-moz-transition: 0.5s;
			-ms-transition: 0.5s;
			-o-transition: 0.5s;
		}

		.rect1,
		.a {
			clip-path: polygon(50% 0%, 65% 15%, 30% 50%, 15% 35%);
			left: 40px;
			top: 15px;
		}

		.rect2,
		.b {
			clip-path: polygon(65% 15%, 100% 50%, 85% 65%, 50% 30%);
			right: 45px;
			top: 15px;
		}
		.rect3,
		.c {
			clip-path: polygon(70% 50%, 85% 65%, 50% 100%, 35% 85%);
			right: 45px;
			bottom: 0px;
			top: 17px;
		}
		.rect4,
		.d {
			clip-path: polygon(15% 35%, 50% 70%, 35% 85%, 0% 50%);
			left: 40px;
			bottom: 0px;
			top: 17px;
		}

		.a:hover,
		.b:hover,
		.c:hover,
		.d:hover {
			z-index: 55555 !important;
			clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
		}

		.rect1:hover,
		.rect2:hover,
		.rect3:hover,
		.rect4:hover {
			z-index: 55555 !important;
			clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
			opacity: 0;
		}

		.rect1:hover ~ .a,
		.rect2:hover ~ .b,
		.rect3:hover ~ .c,
		.rect4:hover ~ .d {
			z-index: 555555 !important;
			clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
		}

		.textA {
			position: absolute;
		}
	}

	.desktop {
		display: flex;
	}
	.mobile {
		display: none;
	}

	@media only screen and (max-width: 1000px) {
		.topline {
			.title-container {
				h4 {
					font-size: 2.8vw;
					margin-left: -13.5%;
				}
			}
		}

		h6 {
			font-size: 3.6vw;
			margin-bottom: 0.25%;
		}

		h4 {
			font-size: 9.5vw;
		}

		p {
			font-size: 2.4vw;
			width: 80%;
			margin-top: 2%;
		}
	}

	@media only screen and (max-width: 600px) {
		.topline {
			.title-container {
				h4 {
					font-size: 4vw;
					margin-left: -19%;
				}
			}
		}

		h6 {
			font-size: 6.5vw;
			margin-bottom: 0;
		}

		h4 {
			font-size: 12vw;
		}

		p {
			font-size: 4.4vw;
			width: 90%;
			margin-top: 5%;
		}

		.content-container {
			.text-container {
				height: 35vh;
			}
		}

		.descriptions {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 0 5%;
			align-items: center;
			h3 {
				color: #000000;
				margin-bottom: 2vw;
			}
			p {
				margin-top: 0;
				margin-bottom: 6vw;
			}
		}

		.desktop {
			display: none;
		}
		.mobile {
			display: flex;
		}
	}
}
