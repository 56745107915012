.about {
	height: 140vh;
	background: url('../images/bg-elements/about-bg.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	.problem-text {
		margin-top: 43vh;
		height: 56vh;
		display: flex;

		.title-container {
			width: 50%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			h4 {
				transform: rotate(90deg);
				font-size: 1.6vw;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				margin-left: -6%;
			}
		}

		.problem {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			padding-right: 3%;
			width: 50%;
			p {
				margin: 2.9% 0;
				text-align: right;
				width: 60%;
				font-size: 1.3vw;
				letter-spacing: 0.08em;
			}
		}
	}

	.solution-text {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 25vh;
		.solution-question {
			font-size: 1.68vw;
			letter-spacing: 0.08em;
			margin-bottom: 1.2rem;
		}

		.solution-answer {
			font-size: 2.4vw;
			letter-spacing: 0.08em;
			// animation: fades 1.2s;
			// transition: opacity 1.2s !important;
			// opacity: 1;
		}

		// @keyframes fades {
		// 	0% {
		// 		opacity: 0;
		// 	}
		// 	100% {
		// 		opacity: 1;
		// 	}
		// }
	}

	@media only screen and (max-width: 1000px) {
		.problem-text {
			.title-container {
				h4 {
					font-size: 2.8vw;
					margin-left: -11%;
				}
			}
			.problem {
				p {
					width: 80%;
					font-size: 2.3vw;
				}
			}
		}
		.solution-text {
			.solution-question {
				font-size: 3.6vw;
				margin-bottom: 1.2rem;
			}

			.solution-answer {
				font-size: 5vw;
			}
		}
	}

	@media only screen and (max-width: 600px) {
		background: url('../images/bg-elements-mobile/about-bg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		.problem-text {
			margin-top: 64vh;
			height: 26vh;
			padding: 0 5%;
			.title-container {
				width: 45%;
				margin-left: 45%;
				margin-top: -30vh;
				height: 30vh;
				position: absolute;
				h4 {
					font-size: 4vw;
					margin-left: -19%;
				}
			}

			.problem {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 80%;
				margin: 0 10%;
				p {
					margin: 2% 0;
					text-align: center;
					width: 100%;
					font-size: 2.9vw;
				}
			}
		}

		.solution-text {
			margin-top: 13.5vh;
			height: 22.5vh;
			.solution-question {
				font-size: 4.6vw;
				margin-bottom: 1.2rem;
			}

			.solution-answer {
				font-size: 7vw;
			}
		}
	}

	@media only screen and (max-width: 430px) {
		.problem-text {
			.title-container {
				h4 {
				}
			}

			.problem {
				width: 90%;
				margin: 0 5%;
				p {
					font-size: 3.9vw;
				}
			}
		}

		.solution-text {
			padding: 0 5%;
		}
	}
}
