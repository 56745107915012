.navbar {
	.logo {
		margin-left: 2vw;
		width: 20vw;
		margin-top: 5%;
	}
	.link-tag:hover {
		text-decoration: none;
		cursor: pointer;
	}
	.nav-text {
		margin-right: 2.5vw;
		text-transform: uppercase;
		font-size: 1.3vw;
		&:after {
			display: block;
			content: '';
			border-bottom: solid 1px #ffffff;
			transform: scaleX(0);
			transition: transform 350ms ease-in-out;
			transform-origin: 0% 50%;
		}
		&:hover:after {
			transform: scaleX(1);
		}
	}

	@media only screen and (max-width: 992px) {
		.logo {
			width: 30vw;
		}
		.nav-text {
			text-align: right;
			margin-right: 0;
			font-size: 3vw;
		}
	}

	@media only screen and (max-width: 800px) {
		.logo {
			width: 40vw;
		}
	}

	@media only screen and (max-width: 576px) {
		.logo {
			width: 50vw;
		}
		.nav-text {
			font-size: 5vw;
		}
	}
}
