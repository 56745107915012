.intro {
	height: 100vh;
	background: url('../images/bg-elements/intro-bg.png') left;
	background-size: 100% 100%;
	background-repeat: no-repeat;

	.intro-text {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 10%;
		max-width: 90%;
		margin: 0 5%;
		animation: fade ease 2s;
		transition: opacity 2s !important;
		opacity: 1;

		@keyframes fade {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		.top {
			margin-bottom: 2%;
			text-align: center;
			font-size: 1.5vw;
			letter-spacing: 0.31em;
			text-shadow: 0px 0px 50px #000000;
		}

		.middle {
			text-align: center;
			font-size: 6.7vw;
			letter-spacing: 0.31em;
			text-shadow: 0px 0px 50px #000000;
			&:after {
				display: block;
				content: '';
				border-bottom: solid 3px #ffffff;
				transform: scaleX(0);
				transition: transform 850ms ease;
				transform-origin: 50% 50%;
			}
			&:hover:after {
				transform: scaleX(1);
			}
		}

		.bottom {
			margin-top: 3%;
			text-align: center;
			font-size: 1.4vw;
			letter-spacing: 0.34em;
			text-shadow: 0px 0px 50px #000000;
		}
	}

	@media only screen and (max-width: 1000px) {
		.intro-text {
			.top {
				margin-bottom: 5%;
				font-size: 3.8vw;
			}

			.middle {
				font-size: 14vw;
				&:after {
					display: none;
				}
			}

			.bottom {
				margin-top: 6%;
				font-size: 3.2vw;
			}
		}
	}

	@media only screen and (max-width: 600px) {
		background: url('../images/bg-elements-mobile/intro-bg.png') left;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
}
