@import url('https://fonts.googleapis.com/css?family=Questrial');

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: Questrial;
	font-style: normal;
	font-weight: normal;
	color: #fff;
}

p {
	font-size: 18px;
}

img {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}
