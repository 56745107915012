.contact {
	height: 114vw;
	background: url('../images/bg-elements/contact-bg.png') center center;
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 24%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	h3 {
		text-align: center;
		font-size: 1.5vw;
		letter-spacing: 0.08em;
		margin-bottom: 0.5%;
		text-transform: uppercase;
	}
	h5 {
		text-align: center;
		font-size: 4.43vw;
		letter-spacing: 0.08em;
		text-transform: uppercase;
	}
	p {
		text-align: center;
		font-size: 1.25vw;
		letter-spacing: 0.08em;
		line-height: 1.4;
		margin-top: 1%;
		margin-bottom: 5%;
	}

	.form {
		padding: 0 35%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.form-group {
			width: 100%;
			margin-bottom: 7%;
		}

		.input-general {
			width: 100%;
			background-color: transparent;
			border-radius: 1.5rem;
			padding: 1.5rem;
			font-family: Questrial;
			font-style: normal;
			font-weight: normal;
			color: #fff;
			border: solid white 1px;
			font-size: 1.25vw;
			line-height: 1.3;
			letter-spacing: 0.08em;
		}

		.input-small {
			text-decoration: none;
			height: 2vw;
		}

		::placeholder {
			color: #fff;
		}

		input:focus,
		select:focus,
		textarea:focus,
		button:focus {
			outline: none;
		}
	}

	.contact-btn {
		width: 100%;
		background: #007b96;
		border-radius: 1000px;
		padding: 5%;
		margin-top: 5%;
		outline: none !important;
		box-shadow: none !important;
		transition: 0.2s ease;
		&:hover {
			background: darken(#007b96, 1%);
		}
		p {
			color: #ffffff;
			font-size: 1.5vw;
			letter-spacing: 0.15em;
			padding: 0;
			margin: 0;
		}
	}

	.contact-text {
		width: 100%;
		color: #ffffff;
		padding: 4.2%;
		font-size: 1.5vw;
		letter-spacing: 0.15em;
		margin-top: 5%;
	}

	.bottom-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		.disclaimer {
			text-align: center;
			font-size: 1.2vw;
			letter-spacing: 0.15em;
			line-height: 1.15;
			margin-bottom: 2%;
		}

		.wdb-badge {
			width: 100%;
			margin: 0;
			margin-bottom: 2%;
			display: flex;
			justify-content: center;
			img {
				width: 15%;
			}
			@media only screen and (max-width: 800px) {
				img {
					width: 20%;
				}
			}
			@media only screen and (max-width: 450px) {
				margin-bottom: 3.5%;
				img {
					width: 30%;
				}
			}
		}
	}

	@media only screen and (max-width: 1000px) {
		height: 160vw;
		padding-top: 35%;
		h3 {
			font-size: 3.9vw;
		}
		h5 {
			font-size: 10vw;
		}
		p {
			font-size: 2.8vw;
		}
		.form {
			padding: 0 25%;
			.input-general {
				font-size: 2.5vw;
			}
		}

		.contact-btn {
			padding: 5%;
			p {
				font-size: 3vw;
			}
		}
		.bottom-text {
			.disclaimer {
				font-size: 1.5vw;
			}
		}
	}

	@media only screen and (max-width: 750px) {
		height: 180vw;
	}

	@media only screen and (max-width: 600px) {
		background: url('../images/bg-elements-mobile/contact-bg.png') center center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	@media only screen and (max-width: 500px) {
		height: 250vw;
		padding-top: 50%;

		h3 {
			font-size: 6.5vw;
		}
		h5 {
			font-size: 12vw;
		}
		p {
			font-size: 4.2vw;
		}

		.form {
			padding: 0 15%;
			.input-general {
				padding: 1rem;
				font-size: 3.5vw;
			}
		}

		.contact-btn {
			padding: 3%;
			p {
				font-size: 5vw;
			}
		}

		.bottom-text {
			.disclaimer {
				font-size: 3vw;
				padding: 0 5%;
				margin-bottom: 4%;
			}
		}
	}
}
