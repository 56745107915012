.tech {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 150vh;
	border-top: 1px solid transparent;

	position: relative;

	.title-container {
		position: absolute;
		top: 4vh;
		width: 50%;
		height: 41vh;
		margin-left: 50%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		h4 {
			transform: rotate(90deg);
			font-size: 1.6vw;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			color: #ffffff;
			margin-left: -10%;
		}
	}

	.text-container {
		margin-top: 45vh;
		height: 84vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	h3,
	h6,
	p {
		letter-spacing: 0.08em;
		text-shadow: 0px 0px 47px #000000;
	}
	h3 {
		font-size: 4.5vw;
		text-transform: uppercase;
	}
	h6 {
		font-size: 1.25vw;
		text-transform: uppercase;
		margin-bottom: 0.5%;
	}
	p {
		font-size: 1.25vw;
		padding-top: 2%;
		width: 45%;
		text-align: center;
	}
	.examples {
		text-transform: uppercase;
		margin-top: 2%;
		margin-bottom: 2.3%;
	}

	.materials {
		align-items: center;
		.material {
			text-align: center;
			.material-btn {
				width: 4vw;
				height: 0.7vw;
				border-radius: 0;
				background: rgba(255, 255, 255, 0.35);
				box-shadow: 0px 0px 47px #000000;
				border-width: 0;
				transition: 0.2s ease;
				&:hover {
					background: rgba(255, 255, 255, 0.5);
				}
			}
			.active-material {
				background: #ffffff;
				&:hover {
					background: #ffffff;
				}
			}
			.material-name {
				margin-top: 8%;
				font-size: 1.25vw;
				letter-spacing: 0.08em;
				text-shadow: 0px 0px 47px #000000;
				text-transform: uppercase;
			}
		}
	}

	.licensing {
		position: absolute;
		bottom: 15vh;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		white-space: nowrap;
		overflow: hidden;
		padding-right: 5%;
		a {
			color: white;
			opacity: 0.5;
			font-size: 0.8rem;
		}
	}
}

.tech-bg-0 {
	background-image: url('../images/tech/RiceStrawBG.png');
}
.tech-bg-1 {
	background-image: url('../images/tech/BagasseBG.png');
}
.tech-bg-2 {
	background-image: url('../images/tech/HempBG.png');
}
.tech-bg-3 {
	background-image: url('../images/tech/EFBBG.png');
}
.tech-bg-4 {
	background-image: url('../images/tech/BambooBG.png');
}
.tech-bg-5 {
	background-image: url('../images/tech/JuteBG.png');
}

@media only screen and (max-width: 1000px) {
	.tech {
		.title-container {
			h4 {
				font-size: 2.8vw;
				margin-left: -18%;
			}
		}
		h3 {
			font-size: 11vw;
		}
		h6 {
			font-size: 3vw;
			margin-bottom: 0.75%;
		}
		p {
			font-size: 2.4vw;
			margin-top: 5%;
			width: 65%;
		}
		.examples {
			display: none;
		}
		.materials {
			display: none;
		}
		.licensing {
			display: none;
		}
	}
}

@media only screen and (max-width: 600px) {
	.tech {
		height: 110vh;
		background-image: url('../images/bg-elements-mobile/tech-bg.png');
		.title-container {
			height: 32vh;
			h4 {
				font-size: 4vw;
				margin-left: -25.5%;
			}
		}
		.text-container {
			margin-top: 32.5vh;
			height: 54vh;
		}
		h3 {
			font-size: 12vw;
		}
		h6 {
			font-size: 4.5vw;
			margin-bottom: 0.75%;
		}
		p {
			font-size: 4.2vw;
			margin-top: 3%;
			width: 90%;
		}
	}
}
