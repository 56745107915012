.scrollTop {
	position: fixed;
	bottom: 20px;
	right: 10px !important;
	z-index: 1000;
	cursor: pointer;
}

.scroll-obj {
	animation: fadeinout 0.3s;
	transition: opacity 0.3s !important;
	opacity: 0.6;
}

.scroll-obj:hover {
	opacity: 1 !important;
}

@keyframes fadeinout {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.5;
	}
}

@media only screen and (max-width: 600px) {
	.scrollTop {
		bottom: 5px;
		right: 5px !important;
	}
}
