.solutions {
	.topline {
		position: relative;
		text-align: center;
		height: 100%;

		.title-container {
			position: absolute;
			top: 0;
			width: 50%;
			height: 100%;
			margin-left: 50%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			h4 {
				transform: rotate(90deg);
				font-size: 1.6vw;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				color: #007b96;
				margin-left: -7%;
			}
		}
	}
	.solutions-content {
		position: relative;
		height: 48vw;
		background: url('../images/bg-elements/solutions-bg.png') center;
		background-size: contain;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.logo {
			position: absolute;
			top: 14%;
		}
		.introduction {
			width: 50%;
			text-align: center;
			.l1 {
				font-size: 1.7vw;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				margin-bottom: 4%;
			}

			.l2 {
				font-size: 4.43vw;
				letter-spacing: 0.08em;
				text-transform: uppercase;
			}

			.l3 {
				font-size: 1.25vw;
				letter-spacing: 0.08em;
				line-height: 1.3;
				margin-top: 6.5%;
			}
		}
	}

	.desktop {
		display: flex;
	}

	.mobile {
		display: none;
	}

	@media only screen and (max-width: 1000px) {
		.topline {
			.title-container {
				h4 {
					font-size: 2.8vw;
					margin-left: -12.5%;
				}
			}
		}
		.solutions-content {
			background: url('../images/bg-elements-mobile/solutions-bg-large.png')
				center;
			background-size: contain;
			background-repeat: no-repeat;
			height: 78vw;
			.introduction {
				width: 70%;
				text-align: center;
				margin-bottom: 2%;
				.l1 {
					font-size: 3.8vw;
					margin-bottom: 2%;
				}

				.l2 {
					font-size: 7.33vw;
				}

				.l3 {
					font-size: 2.8vw;
					margin-top: 3.5%;
				}
			}
		}
	}

	@media only screen and (max-width: 600px) {
		.topline {
			.title-container {
				h4 {
					font-size: 4vw;
					margin-left: -18%;
				}
			}
		}

		.solutions-content {
			background: url('../images/bg-elements-mobile/solutions-bg.png') center;
			background-size: contain;
			background-repeat: no-repeat;
			height: 88vw;

			.introduction {
				width: 78%;
				margin-left: 3%;
				margin-bottom: 9%;
				.l1 {
					font-size: 4.9vw;
					margin-bottom: 1%;
				}

				.l2 {
					font-size: 8.5vw;
				}

				.l3 {
					font-size: 3.6vw;
					margin-top: 1.5%;
				}
			}
		}

		.desktop {
			display: none;
		}

		.mobile {
			display: flex;
		}
	}
}
