.main-page {
	.product-bot {
		height: 60vh;
	}
	.desktop {
		display: flex;
	}
	.mobile {
		display: none;
	}
}
@media only screen and (max-width: 600px) {
	.main-page {
		.product-bot {
			height: 30vh;
		}
		.desktop {
			display: none;
		}
		.mobile {
			display: flex;
		}
	}
}
